import { render, staticRenderFns } from "./MyTransactionHistory.vue?vue&type=template&id=af5ce56e&scoped=true&"
import script from "./MyTransactionHistory.vue?vue&type=script&lang=js&"
export * from "./MyTransactionHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af5ce56e",
  null
  
)

export default component.exports