<template>
  <div class="transaction-history">
    <b-table
      :data="my_currency_history"
      :total="totalTransactions"
      :per-page="perPage"
      paginated
      sortable
    >
      <b-table-column
        v-slot="props"
        field="id"
        label="ID"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="transaction_type"
        label="Type"
      >
        {{ props.row.transaction_type }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="to_user"
        label="To User"
      >
        <div v-if="props.row.to_user">
          <p>{{ props.row.to_user.display_name }} (@{{ props.row.to_user.tag_name }})</p>
        </div>
        <p v-if="!props.row.to_user">-</p>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="to_user"
        label="Uid"
      >
        <div v-if="props.row.to_user">
          <p>{{ props.row.to_user.uid }}</p>
        </div>
        <p v-if="!props.row.to_user">-</p>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="amount"
        label="Amount"
      >
        {{ props.row.amount }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="created_at"
        label="Created at"
      >
        {{ props.row.created_at ? getLocalTime(new Date(props.row.created_at).toLocaleString()) : 'unknown' }}
      </b-table-column>
      <!-- <b-table-column
        label="Detail"
      >
        <b-button
          icon-right="info-circle"
        />
      </b-table-column> -->
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns'
import getUserCashout from '@/graphql/getMyTransactionHistory.gql'

export default {
  name: 'TransactionHistory',
  apollo: {
    my_currency_history: {
      query: getUserCashout,
      variables () {
        return {
          currency_id: 1
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data && data.my_currency_history.length > 0) {
          this.totalTransactions += data.my_currency_history.length
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  },
  data () {
    return {
      perPage: 50,
      totalTransactions: 0
    }
  },
  methods: {
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd/MM/yyyy HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
